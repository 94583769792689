import { IconButton, Tooltip } from '@mui/material';
import { Delete, Replay } from '@mui/icons-material';
import { useDialog } from 'context/dialogContext';
import { useToast } from 'context/toastContext';
import {
  useDeleteCompetencyMapMutation,
  useRestoreCompetencyMapMutation,
} from '@generated/graphql';

const DeleteMapButton = ({
  isDeleted,
  id,
  title,
  refetch,
}: {
  isDeleted: boolean;
  id: string;
  title: string;
  refetch: () => void;
}) => {
  const { showSuccessToast, showErrorToast } = useToast();
  const { showDialog, closeDialog } = useDialog();

  const [deleteMap] = useDeleteCompetencyMapMutation();
  const [restoreMap] = useRestoreCompetencyMapMutation();

  const handleRecoveryAction = () => {
    showDialog({
      title: 'Восстановление карты компетенций',
      content: `Вы уверены, что хотите восстановить карту компетенций ${title}?`,
      confirmText: 'Восстановить',
      onConfirm: async () => {
        return restoreMap({
          variables: {
            competencyMapId: id,
          },
        })
          .then((res) => {
            if (res.data?.restoreCompetencyMap) {
              showSuccessToast('Карта компетенций восстановлена');
              refetch();
              closeDialog();
            } else {
              showErrorToast();
            }
          })
          .catch(() => showErrorToast());
      },
    });
  };

  const handleDeleteAction = () => {
    showDialog({
      title: 'Удаление карты компетенций',
      content: `Вы уверены, что хотите удалить карту компетенций ${title}?`,
      confirmText: 'Удалить',
      onConfirm: async () => {
        return deleteMap({
          variables: {
            competencyMapId: id,
          },
        })
          .then((res) => {
            if (res.data?.deleteCompetencyMap) {
              showSuccessToast('Карта компетенций удалена');
              refetch();
              closeDialog();
            } else {
              showErrorToast();
            }
          })
          .catch(() => showErrorToast());
      },
    });
  };

  const handleClick = () => {
    if (isDeleted) handleRecoveryAction();
    else handleDeleteAction();
  };

  const tooltipText = isDeleted ? 'Восстановить карту компетенций' : 'Удалить карту компетенций';

  return (
    <Tooltip title={tooltipText}>
      <IconButton onClick={handleClick}>{isDeleted ? <Replay /> : <Delete />}</IconButton>
    </Tooltip>
  );
};

export default DeleteMapButton;
