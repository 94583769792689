import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';
import { COMPANY_STATUS_OPTIONS } from 'constants/company';
import { VALIDATION } from 'utils/validation';

const COMPANY_FILTERS: FilterField[] = [
  {
    name: 'createdAt',
    type: FilterInputType.DATE,
    title: 'Дата создания',
  },
  {
    name: 'inn',
    type: FilterInputType.TEXT,
    title: 'ИНН',
    rules: VALIDATION.inn,
  },
  {
    name: 'shortName',
    type: FilterInputType.TEXT,
    title: 'Краткое наименование',
    rules: {
      ...VALIDATION.companyName,
      maxLength: { value: 12, message: 'Максимальная длина ИНН 12 символов' },
    },
  },
  {
    name: 'creatorFullName',
    type: FilterInputType.TEXT,
    title: 'Создатель компании',
    rules: VALIDATION.userName,
  },
  {
    name: 'status',
    type: FilterInputType.AUTOCOMPLETE,
    title: 'Статус',
    options: COMPANY_STATUS_OPTIONS,
  },
];

export default COMPANY_FILTERS;
