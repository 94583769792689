import { TableColumn } from 'layout/TableLayout/types.d';
import { UserCompany } from 'pages/User/types';
import dayjs from 'dayjs';
import { BOOLEAN_LABELS } from 'constants/global';
import { Link } from 'react-router-dom';
import ROUTES from 'constants/routes';

const USER_COMPANY_LIST_COLUMNS: TableColumn<UserCompany>[] = [
  {
    title: 'Дата добавления',
    name: 'createdAt',
    render: ({ createdAt }) => dayjs(createdAt).format('DD.MM.YYYY'),
  },
  {
    title: 'ИНН',
    name: 'inn',
    render: ({ company }) => company.inn,
  },
  {
    title: 'Наименование компании',
    name: 'companyName',
    render: ({ company }) => {
      return <Link to={`/${ROUTES.COMPANIES}/${company.id}`}>{company.shortName}</Link>;
    },
  },
  {
    title: 'Должность',
    name: 'position',
    render: ({ position }) => position,
  },
  {
    title: 'Создатель',
    name: 'isCreator',
    render: ({ isCreator }) => BOOLEAN_LABELS[`${isCreator}`],
  },
  {
    title: 'Руководитель',
    name: 'isSupervisor',
    render: ({ isSupervisor }) => BOOLEAN_LABELS[`${isSupervisor}`],
  },
];

export default USER_COMPANY_LIST_COLUMNS;
