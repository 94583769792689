import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { ToastProvider } from 'context/toastContext';
import { DialogProvider } from 'context/dialogContext';
import { ApolloProvider } from '@apollo/client';
import client from 'config/apolloConfig';
import { MeProvider } from 'context/meContext';
import createAppTheme from './theme';
import router from './router';

const App = () => {
  const theme = createAppTheme();

  return (
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
        <ThemeProvider theme={theme}>
          <MeProvider>
            <ToastProvider>
              <DialogProvider>
                <CssBaseline />
                <RouterProvider router={router} />
              </DialogProvider>
            </ToastProvider>
          </MeProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  );
};

export default App;
