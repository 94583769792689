import { Chip, Stack, Typography } from '@mui/material';
import { CircularLoading, PageTitle } from 'components/common';
import { red } from '@mui/material/colors';
import { useUserHeaderQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import { createUserName } from 'utils/user';
import UserAction from './UserAction';

const Header = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data, loading } = useUserHeaderQuery({
    variables: {
      userId: userId!,
    },
  });

  const user = data?.user;
  const fullName = createUserName(user);

  if (loading) return <CircularLoading />;
  if (!user) return <Typography>Пользователь не найден</Typography>;
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={2}
      justifyContent='space-between'
      alignItems='baseline'>
      <Stack>
        <PageTitle title='Пользователь' />
        <Typography sx={{ overflowWrap: 'anywhere' }}>{fullName}</Typography>
      </Stack>

      <Stack direction='row'>
        {user.isBlocked && <Chip label='Заблокирован' sx={{ backgroundColor: red[200] }} />}
        <UserAction isBlocked={user.isBlocked} fullName={fullName} id={user.id} />
      </Stack>
    </Stack>
  );
};

export default Header;
