import TableLayout from 'layout/TableLayout';
import COMPANY_TABLE from 'pages/Company/List/columns';
import { Stack } from '@mui/material';
import PageTitle from 'components/common/PageTitle';
import COMPANY_FILTERS from 'pages/Company/List/filters';
import { useNavigate } from 'react-router-dom';
import { CompaniesQuery, useCompaniesQuery } from '@generated/graphql';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';

export type CompanyData = NonNullable<NonNullable<CompaniesQuery['companies']>['data']>[number];

const CompanyListPage = () => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useCompaniesQuery({
    variables: {
      limit: DEFAULT_LIMIT,
      page: 0,
    },
  });

  const companies = data?.companies?.data || [];
  const totalCount = data?.companies?.totalCount || 0;

  return (
    <Stack spacing={2.5}>
      <PageTitle title='Список компаний' />
      <TableLayout<CompanyData>
        onRowClick={(row) => navigate(row.id)}
        columns={COMPANY_TABLE}
        filters={COMPANY_FILTERS}
        data={companies}
        loading={loading}
        emptyPlaceholderText='Пока нет компаний'
        totalCount={totalCount}
        rowsPerPageText='Компаний на странице'
        refetch={refetch}
      />
    </Stack>
  );
};

export default CompanyListPage;
