import { IconButton, Stack, Tooltip } from '@mui/material';
import { Download } from '@mui/icons-material';
import { Trial_Status } from '@generated/graphql';
import useDownloadReport from 'hooks/useDownloadReport';
import CircularLoading from 'components/common/CircularLoading';

interface DownloadReportsActionProps {
  trialId: string;
  status: Trial_Status;
  candidateFullName: string;
  completedAt: string;
}

const REPORTS = [
  {
    isExtended: false,
    title: 'Скачать отчет',
  },
  {
    isExtended: true,
    title: 'Скачать расширенный отчет',
  },
];

const DownloadReportsAction = ({
  trialId,
  status,
  candidateFullName,
  completedAt,
}: DownloadReportsActionProps) => {
  const isCompleted = status === Trial_Status.Completed;
  const { onDownloadReport, isLoading } = useDownloadReport();
  if (!isCompleted) return null;
  return (
    <Stack direction='row' spacing={2}>
      {REPORTS?.map((report, index) => {
        const { title, isExtended } = report;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Tooltip title={title} key={`report-${trialId}-${index}`}>
            <IconButton
              onClick={() => onDownloadReport(trialId, isExtended, candidateFullName, completedAt)}>
              {isLoading && isLoading.loading && isLoading.isWithExplanations === isExtended ? (
                <CircularLoading sx={{ p: 0 }} size={20} />
              ) : (
                <Download />
              )}
            </IconButton>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

export default DownloadReportsAction;
