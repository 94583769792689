import { IconButton } from '@mui/material';
import { Star, StarOutline } from '@mui/icons-material';
import { orange } from '@mui/material/colors';
import { useSwitchIsSampleCompetencyMapMutation } from '@generated/graphql';
import { useToast } from 'context/toastContext';
import { CircularLoading } from 'components/common';
import { useDialog } from 'context/dialogContext';

interface SampleMapButtonProps {
  isSample: boolean;
  competencyMapId: string;
}

const SampleMapButton = ({ isSample, competencyMapId }: SampleMapButtonProps) => {
  const { showErrorToast, showSuccessToast } = useToast();
  const { showDialog, closeDialog } = useDialog();
  const [switchSample, { loading }] = useSwitchIsSampleCompetencyMapMutation();

  const onSwitchSample = () => {
    showDialog({
      title: isSample ? 'Удалить  карту из шаблонов' : 'Сделать карту шаблоном',
      content: isSample
        ? 'Вы уверены, что хотите удалить карту компетенций из шаблонов?'
        : 'Вы уверены, что хотите сделать карту компетенций шаблоном?',
      confirmText: 'Подтвердить',
      onConfirm: async () => {
        return switchSample({
          variables: {
            competencyMapId,
          },
        })
          .then((res) => {
            const switchIsSampleCompetencyMap = res.data?.switchIsSampleCompetencyMap;
            if (switchIsSampleCompetencyMap) {
              showSuccessToast(
                switchIsSampleCompetencyMap.isSample
                  ? `Карта компетенций отмечена как шаблон`
                  : 'Карта компетенций больше не является шаблоном',
              );
              closeDialog();
            } else showErrorToast();
          })
          .catch(() => showErrorToast());
      },
    });
  };

  if (loading) return <CircularLoading size={16} sx={{ py: 0 }} />;

  return (
    <IconButton onClick={onSwitchSample}>
      {isSample ? (
        <Star sx={{ color: orange[400] }} />
      ) : (
        <StarOutline sx={{ color: orange[400] }} />
      )}
    </IconButton>
  );
};

export default SampleMapButton;
