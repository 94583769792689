import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';
import { ASSESSMENT_STATUS_OPTIONS } from 'constants/assessment';
import { VALIDATION } from 'utils/validation';

const ASSESSMENT_LIST_FILTERS: FilterField[] = [
  {
    type: FilterInputType.DATE,
    title: 'Дата создания',
    name: 'createAt',
  },
  {
    type: FilterInputType.TEXT,
    title: 'Карта компетенций',
    name: 'competencyMapTitle',
    rules: VALIDATION.competencyMapTitle,
  },
  {
    type: FilterInputType.TEXT,
    title: 'Ник кандидата',
    name: 'telegramUsername',
    rules: VALIDATION.telegramUsername,
  },
  {
    title: 'Статус тестирования',
    type: FilterInputType.AUTOCOMPLETE,
    name: 'status',
    options: ASSESSMENT_STATUS_OPTIONS,
  },
];

export default ASSESSMENT_LIST_FILTERS;
