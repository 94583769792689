import React from 'react';
import {
  AutocompleteChangeReason,
  AutocompleteValue,
} from '@mui/base/useAutocomplete/useAutocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import { createFilterOptions, FilterOptionsState, TextField } from '@mui/material';
import { AutocompleteOption, AutocompleteProps } from './types.d';

/*
eslint-disable react/jsx-props-no-spreading
*/

const useAutocompleteUtils = <TOption extends AutocompleteOption>(
  props: AutocompleteProps<TOption>,
) => {
  const {
    onChange,
    handleOnChange,
    placeholder,
    label,
    error,
    onChangeTextField,
    filterOptionsParams,
    freeSolo,
    optionName = 'displayName',
    getOptionLabel,
    equalityParam = 'id',
  } = props;

  const filter = createFilterOptions<TOption>();

  const handleChange = (
    event: React.SyntheticEvent,
    data: AutocompleteValue<TOption, boolean, boolean, boolean>,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'clear') onChange(null);
    else {
      const newData = handleOnChange?.(data) || data;
      onChange(newData);
    }
  };

  const renderInput = ({ inputProps, ...params }: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      inputProps={inputProps}
      fullWidth
      placeholder={placeholder || label}
      helperText={error?.message}
      error={!!error}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onChangeTextField) {
          const newEvent = onChangeTextField(event);
          inputProps.onChange?.(newEvent);
        } else {
          inputProps.onChange?.(event as React.ChangeEvent<HTMLInputElement>);
        }
      }}
    />
  );

  const handleFilterOptions = (existingOptions: TOption[], params: FilterOptionsState<TOption>) => {
    const newOption = filterOptionsParams?.(params) || params;
    const filtered = filter(existingOptions, newOption);

    if (freeSolo) {
      const { inputValue } = params;
      const isExisting = existingOptions?.some((option) => {
        if (typeof option === 'string') {
          return inputValue === option;
        }
        return inputValue === (option as AutocompleteOption)[optionName];
      });
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          newOption: `Добавить ${inputValue}`,
          [optionName]: inputValue,
          id: undefined,
        } as TOption);
      }
    }

    return filtered;
  };

  const renderOption = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: TOption) => {
    const text =
      option && typeof option === 'object' && 'newOption' in option
        ? option?.newOption
        : getOptionLabel?.(option) || (option as AutocompleteOption)[optionName];
    return (
      <li {...optionProps} key={`${(option as AutocompleteOption)[equalityParam]}-${text}`}>
        {text}
      </li>
    );
  };

  const isOptionEqualToValue = (option: TOption, inputValue: TOption) =>
    (option as AutocompleteOption)[equalityParam] ===
    ((inputValue as AutocompleteOption)[equalityParam] || '');

  return { handleChange, handleFilterOptions, renderOption, renderInput, isOptionEqualToValue };
};

export default useAutocompleteUtils;
