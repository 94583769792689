import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { CalendarIcon } from 'assets/icons';
import { Dayjs } from 'dayjs';

export type DatePickerProps = {
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  maxDate?: Dayjs;
  helperText?: string;
};

const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  label,
  error,
  disabled,
  maxDate,
  helperText,
}) => (
  <Stack spacing={0.5}>
    <MuiDatePicker
      value={value}
      onChange={onChange}
      views={['day', 'month', 'year']}
      format='DD.MM.YYYY'
      disabled={disabled}
      maxDate={maxDate}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      slotProps={{
        field: {
          clearable: true,
        },
        textField: {
          fullWidth: true,
          error,
          label,
          size: 'small',
          helperText,
        },
        openPickerButton: {
          sx: { color: 'base.200' },
        },
        openPickerIcon: {
          sx: { width: 16, height: 16 },
        },
      }}
    />
  </Stack>
);

export default DatePicker;
