/*
eslint-disable no-useless-escape
*/

const cleanSpaceInString = (input: string): string => input.trim().replace(/\s+/g, ' ');

const PATTERN = {
  lastName: {
    value: /^[A-ZА-ЯЁ]+-?[A-ZА-ЯЁ]+$/i,
    message: 'Допускаются только буквы и дефис',
  },
  userName: {
    value: /^[A-ZА-ЯЁ._`-]+$/i,
    message:
      'Допустимы только буквы (латиница или кириллица), а также символы: точка, подчеркивание, апостроф, дефис',
  },
  fullUserName: {
    value: /^[A-ZА-ЯЁ]+([ '_.-][A-ZА-ЯЁ]+)*$/i,
    message:
      'Поле может содержать только буквы (латиница или кириллица), а также символы: точка, подчеркивание, апостроф, дефис',
  },
  email: {
    value: /^[A-ZА-ЯЁ0-9._-]*@?[A-Z0-9.-]*$/i,
    message: 'Недопустимые символы в адресе электронной почты',
  },
  emailFull: {
    value: /^[A-ZА-ЯЁ0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Неверный формат email',
  },
  telegramUsername: {
    value: /^[A-Z0-9_]+$/i,
    message: 'Только буквы латинского алфавита, цифры и нижнее подчеркивание',
  },
  telegramUsernameFull: {
    value: /^[A-Z0-9_]+$/i,
    message: 'Только буквы латинского алфавита, цифры и нижнее подчеркивание',
  },
  inn: {
    value: /^\d+$/,
    message: 'Допустимы только цифры',
  },
  companyName: {
    value: /^[A-ZА-ЯЁ0-9 ~!?@#$%^&*()+`'";:<>\/\\|«»_=№[\].,-]*$/i,
    message: 'Поле может содержать только буквы (латиница или кириллица), цифры, пробелы и символы',
  },
  position: {
    value: /^[A-ZА-ЯЁ\s.,-]*$/i,
    message:
      'Поле может содержать только буквы (латиница или кириллица), пробелы, и символы: дефис, запятая и точка',
  },
  address: {
    value: /^[A-ZА-ЯЁ0-9\s.,/\\-]*$/i,
    message:
      'Поле может содержать только буквы (латиница или кириллица), цифры, пробелы, и символы: дефис, точка, запятая, слэш, обратный слэш',
  },
  competencyMapTitle: {
    value: /^[A-ZА-ЯЁ0-9 ~!?@#$%^&*()+`'";:<>\/\\|«»_=№[\].,-]*$/i,
    message: 'Поле может содержать только буквы (латиница или кириллица), цифры, пробелы и символы',
  },
};

const VALIDATION = {
  userName: {
    validate: (value: string) => {
      const cleanedInput = cleanSpaceInString(value);
      const pattern = PATTERN.userName;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 155, message: 'Длина строки превышает 155 символов' },
  },
  fullUserName: {
    validate: (value: string) => {
      const cleanedInput = cleanSpaceInString(value);
      const pattern = PATTERN.fullUserName;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 155, message: 'Длина строки превышает 155 символов' },
  },
  email: {
    validate: (value: string) => {
      const cleanedInput = value.trim();
      const pattern = PATTERN.email;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 256, message: 'Длина строки превышает 256 символов' },
  },
  emailFull: {
    maxLength: { value: 256, message: 'Длина строки превышает 256 символов' },
    validate: (value: string) => {
      const cleanedInput = value.trim();
      const pattern = PATTERN.emailFull;
      if (!pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
  },
  telegramUsername: {
    validate: (value: string) => {
      const cleanedInput = value.trim();
      const pattern = PATTERN.telegramUsername;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 32, message: 'Длина строки превышает 32 символа' },
  },
  telegramUsernameFull: {
    pattern: PATTERN.telegramUsernameFull,
    maxLength: {
      value: 32,
      message: 'Длина строки превышает 32 символа',
    },
    minLength: {
      value: 5,
      message: 'Минимальная длина - 5 символов',
    },
  },
  inn: {
    validate: (value: string) => {
      const cleanedInput = value.trim();
      const pattern = PATTERN.inn;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 12, message: 'Максимальная длина ИНН 12 символов' },
  },
  companyName: {
    validate: (value: string) => {
      const cleanedInput = value.trim();
      const pattern = PATTERN.companyName;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 256, message: 'Длина строки превышает 256 символов' },
  },
  position: {
    validate: (value: string) => {
      const cleanedInput = value.trim();
      const pattern = PATTERN.position;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 256, message: 'Длина строки превышает 256 символов' },
  },
  address: {
    validate: (value: string) => {
      const cleanedInput = value.trim();
      const pattern = PATTERN.address;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 256, message: 'Длина строки превышает 256 символов' },
  },
  competencyMapTitle: {
    validate: (value: string) => {
      const cleanedInput = value.trim();
      const pattern = PATTERN.competencyMapTitle;
      if (cleanedInput && !pattern.value.test(cleanedInput)) return pattern.message;
      return true;
    },
    maxLength: { value: 256, message: 'Длина строки превышает 256 символов' },
  },
};

export { VALIDATION, PATTERN, cleanSpaceInString };
