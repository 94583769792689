import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import Autocomplete from './Autocomplete';
import { AutocompleteOption, AutocompleteProps } from './types.d';

/*
eslint-disable react/jsx-props-no-spreading
*/

interface AutocompleteControlProps<TOption extends AutocompleteOption>
  extends Omit<AutocompleteProps<TOption>, 'value' | 'onChange'> {
  name: string;
  rules?: UseControllerProps['rules'];
}

const AutocompleteControl = <TOption extends AutocompleteOption>({
  name,
  rules,
  options,
  multiple,
  ...props
}: AutocompleteControlProps<TOption>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules });

  const getValue = () => {
    if (!field.value) return multiple ? [] : null;
    return field.value;
  };

  const value = getValue();

  return (
    <Autocomplete<TOption>
      options={options}
      value={value}
      error={error}
      onChange={field.onChange}
      {...props}
    />
  );
};

export default AutocompleteControl;
