import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import { COMPANY_STATUS_OPTIONS, COMPANY_STATUS_TITLE } from 'constants/company';
import { PageTitle } from 'components/common';
import { Select } from 'components/inputs';
import { useDialog } from 'context/dialogContext';
import {
  Company_Status,
  useCompanyHeaderQuery,
  useSetStatusCompanyMutation,
} from '@generated/graphql';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/common/CircularLoading';
import { useToast } from 'context/toastContext';
import SecretCompanyCode from './SecretCompanyCode';

interface SelectItem {
  id: Company_Status;
  displayName: string;
}

const Header = () => {
  const { showErrorToast, showSuccessToast } = useToast();
  const { companyId } = useParams<{ companyId: string }>();
  const { showDialog, closeDialog } = useDialog();
  const { data, loading } = useCompanyHeaderQuery({
    variables: {
      companyId: companyId!,
    },
  });

  const company = data?.company;

  const availableStatuses = COMPANY_STATUS_OPTIONS;

  const [updateCompanyStatus] = useSetStatusCompanyMutation();

  const handleStatusChange = (event: SelectChangeEvent<Company_Status>) => {
    const newStatus = event.target.value as Company_Status;
    showDialog({
      confirmText: 'Изменить',
      onConfirm: async () => {
        return updateCompanyStatus({
          variables: {
            input: {
              companyId: company!.id,
              newStatus,
            },
          },
        })
          .then((res) => {
            const setStatusCompanyData = res.data?.setStatusCompany;
            if (setStatusCompanyData) {
              const newStatusTitle = COMPANY_STATUS_TITLE[setStatusCompanyData.status];
              showSuccessToast(`Статус компании изменен на "${newStatusTitle}"`);
              closeDialog();
            } else {
              showErrorToast();
            }
          })
          .catch(() => {
            showErrorToast();
          });
      },
      title: 'Изменение статуса компании',
      content: `Вы уверены, что хотите изменить статус компании на ${COMPANY_STATUS_TITLE[newStatus]}?`,
    });
  };

  if (loading) return <CircularLoading />;

  return (
    <Stack
      justifyContent='space-between'
      alignItems={{ xs: 'baseline', md: 'center' }}
      direction={{ xs: 'column', md: 'row' }}>
      <Stack>
        <PageTitle title='Компания' />
        <Typography>{company?.shortName}</Typography>
      </Stack>
      <Stack direction='row' spacing={2}>
        <SecretCompanyCode code={company?.identification} />
        <Select<SelectItem, 'id'>
          value={company?.status}
          labelName='displayName'
          valueName='id'
          items={availableStatuses}
          onChange={handleStatusChange}
        />
      </Stack>
    </Stack>
  );
};

export default Header;
