import React, { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import dayjs from 'dayjs';
import DatePicker, { DatePickerProps } from './DatePicker';

type ISO8601String = string & { __brand: 'ISO8601String' };

type DatePickerControlProps = {
  name: string;
  label?: string;
  rules?: UseControllerProps['rules'];
  defaultValue?: ISO8601String;
  disabled?: boolean;
} & Omit<DatePickerProps, 'value' | 'onChange'>;

const DatePickerControl: FC<DatePickerControlProps> = ({
  name,
  label,
  rules,
  defaultValue,
  disabled,
  maxDate,
  helperText,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: {
      validate: (value) => {
        if (!value) return value;
        const isValid = value.isValid();
        if (!isValid) return 'Неверный формат даты';
        const isBefore = value.isBefore(dayjs('1900-01-01'));
        if (isBefore) return 'Дата не может быть раньше 01.01.1900';
        const isAfter = value.isAfter(dayjs());
        if (isAfter) return 'Нельзя выбрать дату в будущем';
        return value;
      },
      ...rules,
    },
    defaultValue,
  });
  return (
    <DatePicker
      value={typeof field.value === 'undefined' ? null : field.value}
      onChange={field.onChange}
      label={label}
      error={!!error}
      disabled={disabled}
      maxDate={maxDate}
      helperText={error?.message || helperText}
    />
  );
};

export default DatePickerControl;
