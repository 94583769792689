import React, { useEffect, useMemo } from 'react';
import CircularLoading from 'components/common/CircularLoading';
import EmptyList from 'components/common/EmptyList';
import Filters from 'layout/TableLayout/components/Filters';
import Table from 'layout/TableLayout/components/Table';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';
import { Stack } from '@mui/material';
import { Identifiable, TableLayoutProps } from 'layout/TableLayout/types';
import useFilter from 'layout/TableLayout/components/useFilter';
import { merge } from 'lodash';

const TableLayout = <
  Rows extends Identifiable,
  Filter extends Record<string, unknown> | undefined = undefined,
>({
  filters = [],
  error,
  loading,
  emptyPlaceholderText,
  onRowClick,
  columns,
  data,
  totalCount,
  rowsPerPageText,
  refetch,
  defaultFilter,
}: TableLayoutProps<Rows, Filter>) => {
  const location = useLocation();

  const query = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location]);

  const { generateFilterQuery } = useFilter();

  useEffect(() => {
    refetch({
      page: Number(query.page) || 0,
      limit: Number(query.limit) || DEFAULT_LIMIT,
      filter: filters ? merge(defaultFilter, generateFilterQuery(filters)) : defaultFilter || {},
    });
  }, [query]);

  if (loading) return <CircularLoading />;

  return (
    <Stack spacing={2.5}>
      {filters.length > 0 && <Filters filters={filters} />}
      {error || !data || !data.length ? (
        <EmptyList emptyPlaceholderText={emptyPlaceholderText} />
      ) : (
        <Table<Rows> {...{ onRowClick, columns, data, totalCount, rowsPerPageText }} />
      )}
    </Stack>
  );
};

export default TableLayout;
