import { Chip, SxProps } from '@mui/material';
import { useTheme } from '@mui/system';

interface StatusDetail {
  title: string;
  backgroundColor: string;
  color?: string;
}

export type StatusDetails<StatusEnum extends string | number | symbol> = {
  [key in StatusEnum]: StatusDetail;
};

interface StatusProps<StatusEnum extends string | number | symbol> {
  status: StatusEnum;
  sx?: SxProps;
  statusDetails: StatusDetails<StatusEnum>;
}

const Status = <StatusEnum extends string | number | symbol>({
  status,
  statusDetails,
  sx,
}: StatusProps<StatusEnum>) => {
  const theme = useTheme();
  const { title, backgroundColor, color } = statusDetails[status];
  return (
    <Chip
      label={title}
      sx={{
        backgroundColor,
        color: color || theme.palette.getContrastText(backgroundColor),
        ...sx,
      }}
    />
  );
};

export default Status;
