import TableLayout from 'layout/TableLayout';
import ASSESSMENT_LIST_FILTERS from 'pages/Company/View/tabs/AssessmentList/filters';
import {
  CompanyTrialsQuery,
  ListTrialFilterInput,
  useCompanyTrialsQuery,
} from '@generated/graphql';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';
import { useParams } from 'react-router-dom';
import ASSESSMENTS_LIST_COLUMNS from './columns';

export type CompanyTrial = NonNullable<NonNullable<CompanyTrialsQuery['trials']>['data']>[number];

const AssessmentList = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading, refetch } = useCompanyTrialsQuery({
    variables: {
      page: 0,
      limit: DEFAULT_LIMIT,
      filter: { companyId },
    },
  });

  const trials = data?.trials.data;
  const totalCount = data?.trials.totalCount;
  return (
    <TableLayout<CompanyTrial, ListTrialFilterInput>
      filters={ASSESSMENT_LIST_FILTERS}
      columns={ASSESSMENTS_LIST_COLUMNS}
      data={trials}
      loading={loading}
      emptyPlaceholderText='Пока нет тестирований'
      totalCount={totalCount}
      rowsPerPageText='Тестирований на странице'
      refetch={refetch}
      defaultFilter={{
        companyId,
      }}
    />
  );
};

export default AssessmentList;
