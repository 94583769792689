import { TableColumn } from 'layout/TableLayout/types';
import { Employee } from 'pages/Company/View/tabs/EmployeeList/index';
import { BOOLEAN_LABELS } from 'constants/global';
import { createUserName } from 'utils/user';

const EMPLOYEE_LIST_COLUMNS: TableColumn<Employee>[] = [
  {
    title: 'ФИО',
    name: 'fullName',
    render: ({ userEmail: { user } }) => createUserName(user),
  },
  {
    title: 'Email',
    name: 'email',
    render: ({ userEmail }) => userEmail.email,
  },
  {
    title: 'Должность',
    name: 'position',
    render: (employee) => employee.position || '-',
  },
  {
    title: 'Руководитель',
    name: 'isDirector',
    render: ({ isSupervisor }) => BOOLEAN_LABELS[`${isSupervisor}`],
  },
  {
    title: 'Создатель компании',
    name: 'isCreator',
    render: ({ isCreator }) => BOOLEAN_LABELS[`${isCreator}`],
  },
];

export default EMPLOYEE_LIST_COLUMNS;
