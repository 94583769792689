import { UserCompany } from 'pages/User/types';
import TableLayout from 'layout/TableLayout';
import { ListCompanyUserFilterInput, useUserCompaniesQuery } from '@generated/graphql';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';
import { useParams } from 'react-router-dom';
import USER_COMPANY_LIST_COLUMNS from './columns';
import USER_COMPANY_LIST_FILTERS from './filters';

const UserCompanyList = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data, loading, refetch } = useUserCompaniesQuery({
    variables: {
      page: 0,
      limit: DEFAULT_LIMIT,
    },
  });

  const companies = data?.companyUsers?.data;
  const totalCount = data?.companyUsers?.totalCount;

  return (
    <TableLayout<UserCompany, ListCompanyUserFilterInput>
      filters={USER_COMPANY_LIST_FILTERS}
      columns={USER_COMPANY_LIST_COLUMNS}
      data={companies}
      loading={loading}
      emptyPlaceholderText='Пока нет компаний'
      totalCount={totalCount}
      rowsPerPageText='Компаний на странице'
      refetch={refetch}
      defaultFilter={{
        userId,
      }}
    />
  );
};

export default UserCompanyList;
