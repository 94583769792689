import Avatar from '@mui/material/Avatar';
import { createUserName, getInitials, removeAuthCookies } from 'utils/index';
import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { LogoutIcon } from 'assets/icons';
import { useMe } from 'context/meContext';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { useApolloClient } from '@apollo/client';

const User = () => {
  const navigate = useNavigate();
  const { me } = useMe();
  const apolloClient = useApolloClient();

  const userName = createUserName(me);

  const logout = () => {
    removeAuthCookies(['accessToken', 'refreshToken']);
    apolloClient.resetStore().then(() => navigate(ROUTES.LOGIN));
  };

  return (
    <Stack alignItems='center' direction='row'>
      <Stack
        alignItems='center'
        direction='row'
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(`/${ROUTES.USERS}/${me?.id}`)}>
        <Avatar sx={{ width: 33, height: 33, mr: 0.8 }}>{getInitials(userName)}</Avatar>
        <Typography variant='body16rg'>{userName}</Typography>
      </Stack>
      <IconButton onClick={logout}>
        <LogoutIcon sx={{ color: 'primary.main' }} />
      </IconButton>
    </Stack>
  );
};

export default User;
