import { useToast } from 'context/toastContext';
import cookies from 'js-cookie';
import dayjs from 'dayjs';
import { useState } from 'react';

const useDownloadReport = () => {
  const { showErrorToast } = useToast();
  const accessToken = cookies.get('accessToken');

  const [isLoading, setIsLoading] = useState<
    { loading: true; isWithExplanations: boolean } | false
  >(false);

  const onDownloadReport = async (
    trialId: string,
    isWithExplanations: boolean,
    fullName: string | undefined | null,
    date: string,
  ) => {
    setIsLoading({ loading: true, isWithExplanations });
    await fetch(
      `${process.env.REACT_APP_API_URL}/trial/fetch-result?trialId=${trialId}&isWithExplanations=${isWithExplanations}`,
      {
        headers: {
          Authorization: `JWT ${accessToken}`,
        },
      },
    )
      .then((resp) => {
        if (resp.ok) return resp.blob();
        throw new Error();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${fullName}_${dayjs(date).format('DD.MM.YYYY_HH:mm')}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => showErrorToast())
      .finally(() => setIsLoading(false));
  };
  return { onDownloadReport, isLoading };
};

export default useDownloadReport;
