import React, { createContext, useState, useContext, ReactNode, useMemo } from 'react';
import { Snackbar, SnackbarOrigin, Alert, AlertColor } from '@mui/material';

interface ToastContextProps {
  showSuccessToast: (message: string) => void;
  showErrorToast: (message?: string) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toastState, setToastState] = useState<{
    message: string;
    open: boolean;
    severity: AlertColor;
  }>({
    message: '',
    open: false,
    severity: 'success',
  });

  const showSuccessToast = (message: string) => {
    setToastState({ message, open: true, severity: 'success' });
  };

  const showErrorToast = (message?: string) => {
    setToastState({ message: message || 'Произошла ошибка', open: true, severity: 'error' });
  };

  const handleClose = () => {
    setToastState((prevToastState) => ({ ...prevToastState, open: false }));
  };

  const contextValue = useMemo(() => ({ showSuccessToast, showErrorToast }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' } as SnackbarOrigin}
        sx={{ background: 'white', borderRadius: '10px' }}
        open={toastState.open}
        autoHideDuration={3000}
        onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={toastState.severity}
          sx={{
            height: 'auto',
            minWidth: 300,
            '& .MuiAlert-message': {
              py: 1,
              lineHeight: 1.5,
            },
            '& .MuiAlert-icon': {
              py: 1,
            },
          }}
          variant='outlined'>
          {toastState.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
