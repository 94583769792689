import TableLayout from 'layout/TableLayout';
import EMPLOYEE_LIST_COLUMNS from 'pages/Company/View/tabs/EmployeeList/columns';
import EMPLOYEE_LIST_FILTERS from 'pages/Company/View/tabs/EmployeeList/filters';
import {
  CompanyUsersQuery,
  ListCompanyUserFilterInput,
  useCompanyUsersQuery,
} from '@generated/graphql';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../../../../constants/routes';

export type Employee = NonNullable<NonNullable<CompanyUsersQuery['companyUsers']>['data']>[number];

const EmployeeList = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading, refetch } = useCompanyUsersQuery({
    variables: {
      page: 0,
      limit: DEFAULT_LIMIT,
      filter: {
        companyId,
      },
    },
  });

  const users = data?.companyUsers?.data;
  const totalCount = data?.companyUsers?.totalCount;

  return (
    <TableLayout<Employee, ListCompanyUserFilterInput>
      filters={EMPLOYEE_LIST_FILTERS}
      columns={EMPLOYEE_LIST_COLUMNS}
      data={users}
      loading={loading}
      emptyPlaceholderText='Пока нет сотрудников'
      totalCount={totalCount}
      rowsPerPageText='Сотрудников на странице'
      refetch={refetch}
      onRowClick={(companyUser) => navigate(`/${ROUTES.USERS}/${companyUser.userEmail.user.id}`)}
      defaultFilter={{
        companyId,
      }}
    />
  );
};

export default EmployeeList;
