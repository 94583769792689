import { IconButton, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import useModal from 'hooks/useModal';
import { ApolloQueryResult } from '@apollo/client';
import CopyCompetencyMapModal from './CopyCompetencyMapModal';

interface CopyMapButtonProps {
  refetchCompetencyMapList: (variables?: object) => Promise<ApolloQueryResult<object>>;
  title: string;
  id: string;
  company: {
    id: string;
    shortName: string;
  };
}

const CopyMapButton = ({ title, id, refetchCompetencyMapList, company }: CopyMapButtonProps) => {
  const { isOpen, closeModal, showModal } = useModal();

  return (
    <>
      <Tooltip title='Копировать карту компетенций'>
        <IconButton onClick={showModal}>
          <ContentCopy />
        </IconButton>
      </Tooltip>
      <CopyCompetencyMapModal
        isOpen={isOpen}
        onClose={closeModal}
        competencyMapId={id}
        competencyMapTitle={title}
        refetchCompetencyMapList={refetchCompetencyMapList}
        company={company}
      />
    </>
  );
};

export default CopyMapButton;
