import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid2';
import { Button, Stack } from '@mui/material';
import { AutocompleteControl, DatePickerControl, TextFieldControl } from 'components/inputs';
import Form from 'components/form/Form';
import {
  FilterField,
  FilterInputType,
  FiltersProps,
  INPUT_DEFAULT_VALUE,
} from 'layout/TableLayout/types.d';

import useFilter from 'layout/TableLayout/components/useFilter';
import _ from 'lodash';
import dayjs from 'dayjs';

const Filters: React.FC<FiltersProps> = ({ filters }) => {
  const form = useForm({});

  const { handleSubmit, reset } = form;

  const { handleReset, handleFiltersSubmit, query } = useFilter();

  useEffect(() => {
    const params = {};
    filters.forEach((filter: FilterField) => {
      const { name } = filter;
      const inputType = filter.type as FilterInputType;

      const filterData = _.get(query, name);

      if (filterData && inputType === FilterInputType.DATE) {
        const isValid = dayjs(filterData).isValid();
        const dayjsData = isValid ? dayjs(filterData) : null;
        _.set(params, name, dayjsData);
      } else {
        _.set(params, name, filterData || INPUT_DEFAULT_VALUE[inputType]);
      }
    });
    if (Object.keys(params).length) {
      reset(params);
    }
  }, [query]);

  return (
    <Form form={form} onSubmit={handleSubmit((data) => handleFiltersSubmit(data, filters))}>
      <Grid container columnSpacing={2} rowSpacing={4}>
        {filters.map((filter) => (
          <Grid key={filter.name} size={{ xs: 12, md: 3 }}>
            {filter.type === FilterInputType.TEXT && (
              <TextFieldControl name={filter.name} label={filter.title} rules={filter.rules} />
            )}
            {filter.type === FilterInputType.AUTOCOMPLETE && filter.options && (
              <AutocompleteControl
                name={filter.name}
                label={filter.title}
                options={filter.options}
                rules={filter.rules}
              />
            )}
            {filter.type === FilterInputType.DATE && (
              <DatePickerControl
                name={filter.name}
                label={filter.title}
                maxDate={dayjs()}
                rules={filter.rules}
              />
            )}
          </Grid>
        ))}
        <Grid size={{ xs: 12 }} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Stack spacing={2} direction='row'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => handleReset(filters, form)}>
              Сбросить
            </Button>
            <Button type='submit' variant='contained' color='primary'>
              Применить
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Filters;
