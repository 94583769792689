import { generateOptions } from 'utils/format';
import { StatusDetails } from 'components/common/Status';
import palette from 'theme/palette';
import { Trial_Status } from '@generated/graphql';

export const ASSESSMENT_STATUS_TITLE = {
  [Trial_Status.Canceled]: 'Отменено',
  [Trial_Status.Created]: 'Создано',
  [Trial_Status.Received]: 'Получено',
  [Trial_Status.Started]: 'Запущено',
  [Trial_Status.Completed]: 'Завершено',
};

export const ASSESSMENT_STATUS_OPTIONS: Array<{ id: Trial_Status; displayName: string }> =
  generateOptions(ASSESSMENT_STATUS_TITLE);

export const ASSESSMENT_STATUS_COLOR: StatusDetails<Trial_Status> = Object.entries(
  ASSESSMENT_STATUS_TITLE,
).reduce((acc, [key, title]) => {
  const status = key as Trial_Status;
  acc[status] = {
    title,
    backgroundColor: palette.status[status],
  };
  return acc;
}, {} as StatusDetails<Trial_Status>);
