import TableLayout from 'layout/TableLayout';
import {
  CompanyCompetencyMapsQuery,
  ListCompetencyMapFilterInput,
  useCompanyCompetencyMapsQuery,
} from '@generated/graphql';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';
import { useParams } from 'react-router-dom';
import COMPETENCY_MAP_COLUMNS from './columns';
import COMPETENCY_MAP_FILTERS from './filters';

export type CompetencyMap = NonNullable<
  NonNullable<CompanyCompetencyMapsQuery['competencyMaps']>['data']
>[number];

const CompetencyMapList = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading, refetch } = useCompanyCompetencyMapsQuery({
    variables: {
      page: 0,
      limit: DEFAULT_LIMIT,
      filter: {
        companyId,
      },
    },
  });

  const maps = data?.competencyMaps.data;
  const totalCount = data?.competencyMaps.totalCount;

  return (
    <TableLayout<CompetencyMap, ListCompetencyMapFilterInput>
      filters={COMPETENCY_MAP_FILTERS}
      columns={COMPETENCY_MAP_COLUMNS(refetch)}
      data={maps}
      loading={loading}
      emptyPlaceholderText='Пока нет карт компетенций'
      totalCount={totalCount}
      rowsPerPageText='Карт компетенций на странице'
      refetch={refetch}
      defaultFilter={{
        companyId,
      }}
    />
  );
};

export default CompetencyMapList;
