import ROUTES from 'constants/routes';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';

const MENU_ROUTES = [
  {
    title: 'Компании',
    link: ROUTES.COMPANIES,
    icon: <BusinessIcon />,
  },
  {
    title: 'Пользователи',
    link: ROUTES.USERS,
    icon: <PeopleIcon />,
  },
  {
    title: 'Карты компетенций',
    link: ROUTES.COMPETENCY_MAPS,
    icon: <EmojiObjectsOutlinedIcon />,
  },
];

export default MENU_ROUTES;
