import React from 'react';
import { TextFieldProps } from '@mui/material';
import { useController, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import TextField from './TextField';

interface TextFieldControlProps<T extends FieldValues> extends Omit<TextFieldProps, 'name'> {
  name: Path<T>;
  rules?: UseControllerProps<T>['rules'];
}

const TextFieldControl = <T extends FieldValues>({
  name,
  rules,
  label,
  placeholder,
  size = 'small',
  helperText,
  type,
}: TextFieldControlProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    rules,
  });

  return (
    <TextField
      type={type}
      onChange={onChange}
      value={value}
      error={error}
      label={label}
      placeholder={placeholder}
      size={size}
      helperText={helperText}
    />
  );
};

export default TextFieldControl;
