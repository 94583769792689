import { GradeColor } from 'types/mui';
import palette from 'theme/palette';
import { generateOptions } from 'utils/format';
import { grey } from '@mui/material/colors';
import { Company_Status, Organization_State, Type_Of_Organization } from '@generated/graphql';

export const COMPANY_STATUS_TITLE = {
  [Company_Status.PartlyApproved]: 'Частично одобрена',
  [Company_Status.Approved]: 'Подтверждена',
  [Company_Status.NotApproved]: 'Не подтверждена',
  [Company_Status.Blocked]: 'Заблокирована',
  [Company_Status.Created]: 'Создана',
};

export const COMPANY_STATUS: {
  [key in Company_Status]: {
    color: GradeColor['light'];
    backgroundColor: GradeColor['contrast'];
    title: string;
  };
} = {
  [Company_Status.PartlyApproved]: {
    color: palette.orange.light,
    backgroundColor: palette.orange.contrast,
    title: COMPANY_STATUS_TITLE[Company_Status.PartlyApproved],
  },
  [Company_Status.Approved]: {
    color: palette.green.light,
    backgroundColor: palette.green.contrast,
    title: COMPANY_STATUS_TITLE[Company_Status.Approved],
  },
  [Company_Status.NotApproved]: {
    color: palette.red.light,
    backgroundColor: palette.red.contrast,
    title: COMPANY_STATUS_TITLE[Company_Status.NotApproved],
  },
  [Company_Status.Blocked]: {
    color: palette.red.light,
    backgroundColor: palette.red.contrast,
    title: COMPANY_STATUS_TITLE[Company_Status.Blocked],
  },
  [Company_Status.Created]: {
    color: grey[400],
    backgroundColor: grey[700],
    title: COMPANY_STATUS_TITLE[Company_Status.Created],
  },
};

export const COMPANY_STATUS_OPTIONS: Array<{ id: Company_Status; displayName: string }> =
  generateOptions(COMPANY_STATUS_TITLE);

export const COMPANY_TYPE = {
  [Type_Of_Organization.Individual]: 'Индивидуальные предприниматель',
  [Type_Of_Organization.Legal]: 'Юридическое лицо',
};

export const COMPANY_TYPE_OPTIONS: Array<{ id: Type_Of_Organization; displayName: string }> =
  generateOptions(COMPANY_TYPE);

export const COMPANY_STATE_TITLE = {
  [Organization_State.Active]: 'Действующая',
  [Organization_State.Bankrupt]: 'Банкротство',
  [Organization_State.Liquidated]: 'Ликвидирована',
  [Organization_State.Liquidating]: 'Ликвидируется',
  [Organization_State.Reorganizing]: 'В процессе присоединения к другому юрлицу',
};

export const COMPANY_STATE_OPTIONS: Array<{ id: Organization_State; displayName: string }> =
  generateOptions(COMPANY_STATE_TITLE);
