import React, { FC, ReactNode, useState } from 'react';
import MuiTextField from '@mui/material/TextField';
import { IconButton, Stack, TextFieldProps as MuiTextFieldProps, Tooltip } from '@mui/material';
import { FieldError } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ErrorOutline } from '@mui/icons-material';

interface TextFieldProps extends Omit<MuiTextFieldProps, 'error'> {
  error?: FieldError;
  endAdornment?: ReactNode;
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    placeholder,
    size = 'small',
    label,
    type,
    variant,
    onChange,
    value = '',
    onBlur,
    error,
    helperText,
    endAdornment,
  } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const isPassword = type === 'password';

  const generateEndIcon = () => {
    if (isPassword) {
      return (
        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
          {showPassword ? (
            <VisibilityIcon sx={{ color: 'base.200' }} />
          ) : (
            <VisibilityOffIcon sx={{ color: 'base.200' }} />
          )}
        </IconButton>
      );
    }
    if (endAdornment) return endAdornment;
    return undefined;
  };
  const endIcon = generateEndIcon();

  const getInputType = () => {
    if (isPassword) {
      if (showPassword) return 'text';
      return 'password';
    }
    return type;
  };

  return (
    <Stack spacing={0.5}>
      <MuiTextField
        fullWidth
        label={label}
        type={getInputType()}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={!!error}
        placeholder={placeholder}
        size={size}
        helperText={helperText}
        slotProps={{
          input: {
            endAdornment: (
              <>
                {error?.message ? (
                  <Tooltip title={error.message}>
                    <ErrorOutline />
                  </Tooltip>
                ) : null}
                {endIcon}
              </>
            ),
          },
        }}
      />
    </Stack>
  );
};

export default TextField;
