import React, { useEffect, useMemo } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Box, Paper } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';

export interface TabContent {
  label: string;
  id: string;
  Component: React.ComponentType;
}

interface TabLayoutProps {
  tabs: TabContent[];
}

const TabLayout: React.FC<TabLayoutProps> = ({ tabs }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);

  const [value, setValue] = React.useState(tabs[0].id);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchParams(qs.stringify({ tab: newValue }));
  };

  useEffect(() => {
    if (query.tab) setValue(query.tab as string);
  }, [query]);

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange}>
            {tabs.map((tab) => (
              <Tab key={tab.id} label={tab.label} value={tab.id} />
            ))}
          </TabList>
        </Box>
        <Paper elevation={0} square>
          {tabs.map((tab) => (
            <TabPanel key={tab.id} value={tab.id}>
              <tab.Component />
            </TabPanel>
          ))}
        </Paper>
      </TabContext>
    </Box>
  );
};

export default TabLayout;
