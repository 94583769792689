import React from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import { Button, Chip, FormHelperText, Stack, Tooltip } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface FileUploadButtonProps {
  name: string;
  multiple?: boolean;
  accept?: string;
  buttonText?: string;
  rules?: RegisterOptions;
}

interface FileChipProps {
  file: File;
  handleDelete: (file: File) => void;
}

const FileChip = ({ file, handleDelete }: FileChipProps) => {
  return (
    <Tooltip title={file.name}>
      <Chip
        sx={{ width: 180 }}
        label={file.name}
        onDelete={() => handleDelete(file)}
        color='primary'
        variant='outlined'
      />
    </Tooltip>
  );
};

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  name,
  multiple = false,
  accept,
  buttonText = 'Загрузить файл',
  rules,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    defaultValue: multiple ? [] : null,
    rules,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const fileList = multiple ? Array.from(files) : files[0];
      onChange(fileList);
    }
  };

  const handleDelete = (fileToDelete: File) => {
    if (multiple) {
      onChange((value as File[]).filter((file) => file !== fileToDelete));
    } else {
      onChange(null);
    }
  };

  return (
    <Stack spacing={0.5} alignItems='baseline'>
      <Button variant='contained' component='label' startIcon={<CloudUploadIcon />}>
        {buttonText}
        <input type='file' hidden multiple={multiple} onChange={handleFileChange} accept={accept} />
      </Button>
      <Stack>
        {multiple
          ? (value as File[])?.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <FileChip key={`add-file-${index}`} file={file} handleDelete={handleDelete} />
            ))
          : value && <FileChip file={value} handleDelete={handleDelete} />}
      </Stack>
      {error && <FormHelperText sx={{ color: 'error.main' }}>{error.message}</FormHelperText>}
    </Stack>
  );
};

export default FileUploadButton;
