import { Button, Stack } from '@mui/material';
import TableLayout from 'layout/TableLayout';
import PageTitle from 'components/common/PageTitle';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';
import { useCompetencyMapListQuery } from '@generated/graphql';
import COMPETENCY_MAP_FILTERS from 'pages/CompetencyMap/list/filters';
import COMPETENCY_MAP_TABLE from 'pages/CompetencyMap/list/columns';
import useModal from 'hooks/useModal';
import AddCompetencyMapModal from 'pages/CompetencyMap/list/components/AddCompetencyMapModal';
import { CompetencyMapData } from 'pages/CompetencyMap/list/types';

const CompetencyMapListPage = () => {
  const { isOpen, showModal, closeModal } = useModal();
  const { data, loading, refetch } = useCompetencyMapListQuery({
    variables: {
      page: 0,
      limit: DEFAULT_LIMIT,
    },
  });

  const competencyMaps = data?.competencyMaps.data;
  const totalCount = data?.competencyMaps.totalCount;

  return (
    <Stack spacing={2.5}>
      <Stack direction='row' justifyContent='space-between'>
        <PageTitle title='Список карт компетенций' />
        <Button variant='contained' onClick={showModal}>
          Загрузить карту
        </Button>
      </Stack>
      <TableLayout<CompetencyMapData>
        columns={COMPETENCY_MAP_TABLE(refetch)}
        filters={COMPETENCY_MAP_FILTERS}
        data={competencyMaps}
        loading={loading}
        emptyPlaceholderText='Пока нет карт компетеций'
        totalCount={totalCount}
        rowsPerPageText='Карт компетенций на странице'
        refetch={refetch}
      />
      {isOpen && <AddCompetencyMapModal open={isOpen} onClose={closeModal} refetchList={refetch} />}
    </Stack>
  );
};

export default CompetencyMapListPage;
