import React, { createContext, useContext, useState, ReactNode, FC, useMemo } from 'react';
import { Dialog, DialogProps } from 'components/modals';

interface DialogContextProps {
  showDialog: (props: Omit<DialogProps, 'open' | 'onClose' | 'isLoading'>) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

const DialogProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [dialogProps, setDialogProps] = useState<Omit<DialogProps, 'open' | 'onClose'> | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const showDialog = (props: Omit<DialogProps, 'open' | 'onClose'>) => {
    setDialogProps(props);
  };

  const closeDialog = () => {
    setDialogProps(null);
  };

  const handleConfirm = async () => {
    if (dialogProps?.onConfirm) {
      setLoading(true);
      try {
        await dialogProps.onConfirm();
      } finally {
        setLoading(false);
      }
    }
  };

  const contextValue = useMemo(() => ({ showDialog, closeDialog }), [showDialog, closeDialog]);

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      {dialogProps && (
        <Dialog
          isLoading={loading}
          title={dialogProps.title}
          content={dialogProps.content}
          confirmText={dialogProps.confirmText}
          cancelText={dialogProps.cancelText}
          onConfirm={handleConfirm}
          open={Boolean(dialogProps)}
          onClose={closeDialog}
        />
      )}
    </DialogContext.Provider>
  );
};

const useDialog = (): DialogContextProps => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

export { DialogProvider, useDialog };
