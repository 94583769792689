import { Stack, SxProps, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { KeyIcon } from 'assets/icons';
import { useToast } from 'context/toastContext';

interface SecretCompanyCodeProps {
  code: string | undefined;
  sx?: SxProps;
}

const SecretCompanyCode = ({ code, sx }: SecretCompanyCodeProps) => {
  const { showSuccessToast } = useToast();

  const onCopyText = () => {
    navigator.clipboard.writeText(code!).then(() => showSuccessToast('Код успешно скопирован'));
  };

  if (!code) return null;

  return (
    <Tooltip
      title='Код&nbsp;компании для&nbsp;доступа кандидатов к чат-боту'
      arrow
      componentsProps={{
        popper: {
          sx: {
            [`&[data-popper-placement*="bottom"] > .${tooltipClasses.tooltip}`]: {
              marginTop: '8px',
            },
            [`.${tooltipClasses.tooltip}`]: {
              maxWidth: 200,
              textAlign: 'center',
            },
          },
        },
      }}>
      <Stack
        onClick={onCopyText}
        direction='row'
        alignItems='center'
        sx={{ cursor: 'pointer', ...sx }}>
        <KeyIcon sx={{ width: 32, height: 32, mr: 1 }} />
        <Typography variant='body16rg'>{code}</Typography>
      </Stack>
    </Tooltip>
  );
};

export default SecretCompanyCode;
