import { TableColumn } from 'layout/TableLayout/types.d';
import { UserList } from 'pages/User/types';
import dayjs from 'dayjs';
import { BOOLEAN_LABELS } from 'constants/global';
import { createUserName } from 'utils/user';

const USER_LIST_COLUMNS: TableColumn<UserList>[] = [
  {
    title: 'Дата создания',
    name: 'createdAt',
    render: ({ createdAt }) => dayjs(createdAt).format('DD.MM.YYYY'),
  },
  {
    title: 'ФИО',
    name: 'fullName',
    render: (user) => createUserName(user),
  },
  {
    title: 'Email',
    name: 'email',
    render: ({ userEmail }) => userEmail.email,
  },
  {
    title: 'Заблокирован',
    name: 'isBlocked',
    render: ({ isBlocked }) => BOOLEAN_LABELS[`${isBlocked}`],
  },
];

export default USER_LIST_COLUMNS;
