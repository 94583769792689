import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { useState } from 'react';
import { useDialog } from 'context/dialogContext';
import { useBlockUnblockUserMutation } from '@generated/graphql';
import { useToast } from 'context/toastContext';

interface UserActionProps {
  isBlocked: boolean;
  fullName: string;
  id: string;
}

const UserAction = ({ isBlocked, fullName, id }: UserActionProps) => {
  const { showSuccessToast, showErrorToast } = useToast();
  const { showDialog, closeDialog } = useDialog();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const actionBlockText = isBlocked ? 'Разблокировать' : 'Заблокировать';

  const [blockUnblockUser] = useBlockUnblockUserMutation();

  const onBlockUser = () => {
    handleCloseMenu();
    showDialog({
      title: isBlocked ? 'Разблокировать пользователя' : 'Заблокировать пользователя',
      content: isBlocked
        ? `Вы уверены, что хотите разблокировать пользователя ${fullName}?`
        : `Вы уверены, что хотите заблокировать пользователя ${fullName}?`,
      onConfirm: async () =>
        blockUnblockUser({
          variables: {
            userId: id,
          },
        })
          .then((res) => {
            const blockUnblockUserData = res.data?.blockUnblockUser;
            if (blockUnblockUserData) {
              showSuccessToast(
                blockUnblockUserData.isBlocked
                  ? 'Пользователь заблокирован'
                  : 'Пользователь разблокирован',
              );
              closeDialog();
            } else {
              showErrorToast();
            }
          })
          .catch(() => showErrorToast()),
      confirmText: actionBlockText,
    });
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuItem onClick={onBlockUser}>{actionBlockText}</MenuItem>
      </Menu>
    </div>
  );
};

export default UserAction;
