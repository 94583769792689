import TableLayout from 'layout/TableLayout';
import { UserList } from 'pages/User/types';
import USER_LIST_COLUMNS from 'pages/User/List/columns';
import USER_LIST_FILTERS from 'pages/User/List/filters';
import { useNavigate } from 'react-router-dom';
import { useUsersQuery } from '@generated/graphql';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';
import { Stack } from '@mui/material';
import PageTitle from 'components/common/PageTitle';

const UserListPage = () => {
  const navigate = useNavigate();
  const { data, refetch, loading } = useUsersQuery({
    variables: {
      limit: DEFAULT_LIMIT,
      page: 0,
    },
  });

  const users = data?.users.data;
  const totalCount = data?.users.totalCount;

  return (
    <Stack spacing={2.5}>
      <PageTitle title='Список пользователей' />
      <TableLayout<UserList>
        onRowClick={(user) => navigate(user.id)}
        filters={USER_LIST_FILTERS}
        columns={USER_LIST_COLUMNS}
        data={users}
        loading={loading}
        emptyPlaceholderText='Пока нет пользователей'
        totalCount={totalCount}
        rowsPerPageText='Пользователей на странице'
        refetch={refetch}
      />
    </Stack>
  );
};

export default UserListPage;
