import { createContext, ReactNode, useContext, useMemo } from 'react';
import { Exact, MeQuery, useMeQuery } from '@generated/graphql';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { CircularLoading } from 'components/common';

interface MeContextValues {
  me: MeQuery['me'] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch?: (
    variables?: Partial<Exact<{ [p: string]: never }>> | undefined,
  ) => Promise<ApolloQueryResult<MeQuery>>;
}

const MeContext = createContext<MeContextValues>({
  me: undefined,
  loading: false,
  error: undefined,
});

const MeProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading, error, refetch } = useMeQuery({
    notifyOnNetworkStatusChange: true,
  });
  const me = data?.me;

  const values = useMemo(() => ({ me, loading, error, refetch }), [me, loading, error]);

  return (
    <MeContext.Provider value={values}>
      {loading ? <CircularLoading /> : children}
    </MeContext.Provider>
  );
};

const useMe = (): MeContextValues => {
  const context = useContext(MeContext);
  if (context === undefined) {
    throw new Error('useMe must be used within a MeProvider');
  }
  return context;
};

export { MeProvider, useMe };
