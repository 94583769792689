import { AutocompleteControl, DatePickerControl, TextFieldControl } from 'components/inputs';
import Form from 'components/form/Form';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { COMPANY_STATE_OPTIONS, COMPANY_TYPE_OPTIONS } from 'constants/company';
import { CircularLoading } from 'components/common';
import dayjs, { Dayjs } from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useToast } from 'context/toastContext';
import {
  Organization_State,
  Type_Of_Organization,
  useCompanyDataQuery,
  useUpdateCompanyMutation,
} from '@generated/graphql';
import { useParams } from 'react-router-dom';
import { cleanSpaceInString, VALIDATION } from 'utils/validation';

interface CompanyFormData {
  inn: string;
  fullCompanyName: string;
  shortCompanyName: string;
  kpp: string;
  ogrn: string;
  ogrnDate: Dayjs | null;
  companyType: { id: Type_Of_Organization; displayName: string } | null;
  directorName: string;
  directorPosition: string;
  okpo: string;
  okato: string;
  oktmo: string;
  address: string;
  registrationDate: Dayjs | null;
  state: { id: Organization_State; displayName: string } | null;
}

const CompanyData = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { showErrorToast, showSuccessToast } = useToast();

  const { data, loading } = useCompanyDataQuery({
    variables: {
      companyId: companyId!,
    },
  });

  const company = data?.company;

  const form = useForm<CompanyFormData>({
    values: {
      inn: company?.inn || '',
      fullCompanyName: company?.fullName || '',
      shortCompanyName: company?.shortName || '',
      kpp: company?.kpp || '',
      ogrn: company?.ogrn || '',
      companyType: COMPANY_TYPE_OPTIONS.find((option) => option.id === company?.type) || null,
      directorName: company?.managerFio || '',
      directorPosition: company?.managerPosition || '',
      okpo: company?.okpo || '',
      okato: company?.okato || '',
      oktmo: company?.oktmo || '',
      address: company?.registrationAddress || '',
      state: COMPANY_STATE_OPTIONS.find((option) => option.id === company?.state) || null,
      ogrnDate: company?.ogrnDate ? dayjs(company.ogrnDate) : null,
      registrationDate: company?.registrationDate ? dayjs(company.registrationDate) : null,
    },
  });

  const { handleSubmit } = form;

  const [updateCompany, { loading: updateCompanyLoading }] = useUpdateCompanyMutation();

  const onError = () => showErrorToast('Проверьте правильность заполнения формы');

  const onSubmit = (formData: CompanyFormData) => {
    updateCompany({
      variables: {
        input: {
          id: company!.id,
          fullName: cleanSpaceInString(formData.fullCompanyName),
          shortName: cleanSpaceInString(formData.shortCompanyName),
          inn: formData.inn.trim(),
          kpp: formData.kpp.trim(),
          managerFio: cleanSpaceInString(formData.directorName),
          managerPosition: cleanSpaceInString(formData.directorPosition),
          ogrn: formData.ogrn.trim(),
          ogrnDate: formData.ogrnDate!.valueOf(),
          okato: formData.okato.trim(),
          okpo: formData.okpo.trim(),
          oktmo: formData.oktmo.trim(),
          registrationAddress: cleanSpaceInString(formData.address),
          registrationDate: formData.registrationDate!.valueOf(),
          state: formData.state!.id,
          type: formData.companyType!.id,
        },
      },
    })
      .then(() => {
        showSuccessToast('Данные сохранены');
      })
      .catch(() => onError());
  };

  if (loading) return <CircularLoading />;

  return (
    <Form form={form}>
      <Grid container columnSpacing={3.5} rowSpacing={3.5}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack spacing={4}>
            <TextFieldControl
              label='ИНН'
              name='inn'
              rules={{
                required: true,
                validate: (value, formData: CompanyFormData) => {
                  if (typeof value !== 'string') return false;

                  const patternError = VALIDATION.inn.validate(value);
                  if (patternError !== true) return patternError;

                  if (
                    formData.companyType?.id === Type_Of_Organization.Legal &&
                    value.trim().length !== 10
                  ) {
                    return 'Длина ИНН юридического лица должна быть 10 символов';
                  }
                  if (
                    formData.companyType?.id === Type_Of_Organization.Individual &&
                    value.trim().length !== 12
                  ) {
                    return 'Длина ИНН физического лица должна быть 12 символов';
                  }
                  return true;
                },
              }}
            />
            <TextFieldControl
              label='Полное наименование'
              name='fullCompanyName'
              rules={{ required: true, ...VALIDATION.companyName }}
            />
            <TextFieldControl
              label='Краткое наименование'
              name='shortCompanyName'
              rules={{ required: true, ...VALIDATION.companyName }}
            />
            <TextFieldControl
              label='КПП'
              name='kpp'
              rules={{
                required: true,
                validate: (value) => {
                  const cleanedValue = value.trim();
                  const pattern = /^[0-9]{9}$/;
                  if (!pattern.test(cleanedValue)) return 'Должно быть 9 цифр';
                  return true;
                },
              }}
            />
            <TextFieldControl
              label='ОГРН'
              name='ogrn'
              rules={{
                required: true,
                validate: (value) => {
                  const cleanedValue = value.trim();
                  const pattern = /^[0-9]{13}$/;
                  if (!pattern.test(cleanedValue)) return 'Должно быть 13 цифр';
                  return true;
                },
              }}
            />
            <DatePickerControl
              name='ogrnDate'
              label='Дата выдачи ОГРН'
              rules={{ required: true }}
              maxDate={dayjs()}
            />
            <AutocompleteControl
              name='companyType'
              label='Тип организации'
              options={COMPANY_TYPE_OPTIONS}
              rules={{ required: true }}
            />
            <TextFieldControl
              label='ФИО руководителя'
              name='directorName'
              rules={{
                required: true,
                ...VALIDATION.fullUserName,
              }}
            />
            <TextFieldControl
              label='Должность руководителя'
              name='directorPosition'
              rules={{
                required: true,
                ...VALIDATION.position,
              }}
            />
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack spacing={4}>
            <TextFieldControl
              label='ОКПО'
              name='okpo'
              rules={{
                required: true,
                validate: (value) => {
                  const cleanedValue = value.trim();
                  const pattern = /^(?:[0-9]{8}|[0-9]{10}|[0-9]{14})$/;
                  if (!pattern.test(cleanedValue)) return 'Должно быть 8, 10 или 14 цифр';
                  return true;
                },
              }}
            />
            <TextFieldControl
              label='ОКАТО'
              name='okato'
              rules={{
                required: true,
                validate: (value) => {
                  const cleanedValue = value.trim();
                  const pattern = /^[0-9]{2,11}$/;
                  if (!pattern.test(cleanedValue)) return 'Должно быть от 2 до 11 цифр';
                  return true;
                },
              }}
            />
            <TextFieldControl
              label='ОКТМО'
              name='oktmo'
              rules={{
                required: true,
                validate: (value) => {
                  const cleanedValue = value.trim();
                  const pattern = /^(?:[0-9]{8}|[0-9]{11})$/;
                  if (!pattern.test(cleanedValue)) return 'Должно быть 8 или 11 цифр';
                  return true;
                },
              }}
            />
            <TextFieldControl
              label='Адрес организации'
              name='address'
              rules={{ required: true, ...VALIDATION.address }}
            />
            <DatePickerControl
              name='registrationDate'
              label='Дата регистрации'
              maxDate={dayjs()}
              rules={{ required: true }}
            />
            <AutocompleteControl
              options={COMPANY_STATE_OPTIONS}
              label='Статус организации'
              name='state'
              rules={{ required: true }}
            />
          </Stack>
        </Grid>
        <Grid size={12}>
          <LoadingButton
            variant='contained'
            size='large'
            loading={updateCompanyLoading}
            onClick={handleSubmit(onSubmit, onError)}>
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CompanyData;
