import { useLoginByCredentialsMutation } from '@generated/graphql';
import { Card, Container, Stack } from '@mui/material';
import Form from 'components/form/Form';
import { useForm } from 'react-hook-form';
import { useToast } from 'context/toastContext';
import { TextFieldControl } from 'components/inputs';
import { LoadingButton } from '@mui/lab';
import cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useMe } from 'context/meContext';
import { useEffect } from 'react';
import ROUTES from 'constants/routes';

interface LoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();
  const [login, { loading }] = useLoginByCredentialsMutation();
  const { showErrorToast } = useToast();
  const form = useForm<LoginForm>();
  const { handleSubmit } = form;
  const { me, loading: meLoading, error: meError, refetch: meRefetch } = useMe();

  useEffect(() => {
    if (!meLoading && !meError && me) navigate(`/${ROUTES.COMPANIES}`);
  }, [me, meLoading, meError]);

  const onError = () =>
    showErrorToast('Что-то пошло не так. Проверьте правильность введенных данных');

  const onSubmit = (formData: LoginForm) => {
    login({
      variables: {
        email: formData.email,
        password: formData.password,
      },
    })
      .then((res) => {
        const loginByCredentials = res.data?.loginByCredentials;
        if (loginByCredentials) {
          const { accessToken, refreshToken } = loginByCredentials;
          cookies.set('accessToken', accessToken);
          cookies.set('refreshToken', refreshToken);
          meRefetch?.();
          navigate(`/${ROUTES.COMPANIES}`);
        } else onError();
      })
      .catch(() => onError());
  };

  return (
    <Container
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Card sx={{ minWidth: { xs: '100%', sm: 300 } }}>
        <Form form={form}>
          <Stack spacing={2}>
            <TextFieldControl
              label='Email'
              type='email'
              name='email'
              placeholder='example@mail.com'
              rules={{ required: true }}
            />
            <TextFieldControl
              label='Пароль'
              name='password'
              type='password'
              placeholder='*****'
              rules={{ required: true }}
            />
            <LoadingButton
              variant='contained'
              loading={loading}
              onClick={handleSubmit(onSubmit, onError)}>
              Войти
            </LoadingButton>
          </Stack>
        </Form>
      </Card>
    </Container>
  );
};

export default Login;
