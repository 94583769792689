import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';
import { BOOLEAN_OPTIONS } from 'constants/global';
import { VALIDATION } from 'utils/validation';

const USER_LIST_FILTERS: FilterField[] = [
  {
    title: 'Дата создания',
    type: FilterInputType.DATE,
    name: 'createdAt',
  },
  {
    title: 'ФИО',
    type: FilterInputType.TEXT,
    name: 'userFullName',
    rules: VALIDATION.userName,
  },
  {
    title: 'Email',
    type: FilterInputType.TEXT,
    name: 'email',
    rules: VALIDATION.email,
  },
  {
    title: 'Заблокирован',
    type: FilterInputType.AUTOCOMPLETE,
    name: 'isBlocked',
    options: BOOLEAN_OPTIONS,
    customFilter: (value) => {
      const filterValue = value as { id: string; displayName: string };
      return filterValue.id === 'true';
    },
  },
];

export default USER_LIST_FILTERS;
