import React, { ReactElement } from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export interface DialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  title: string;
  content?: string | ReactElement | null;
  confirmText: string;
  cancelText?: string;
  isLoading?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  content = null,
  confirmText,
  cancelText = 'Отменить',
  isLoading = false,
}) => {
  return (
    <MuiDialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle sx={{ px: 0, textAlign: 'center' }}>{title}</DialogTitle>
      {content && typeof content === 'string' ? (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      ) : (
        content
      )}
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          {cancelText}
        </Button>
        <LoadingButton onClick={onConfirm} loading={isLoading} color='primary' variant='contained'>
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </MuiDialog>
  );
};
